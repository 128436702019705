<h1 class="mat-headline-5 dialog-header margin-right-5" mat-dialog-title>
  <ng-container>{{ message }}</ng-container>
</h1>
<ng-container>
  <erp-button color="primary" variant="icon" class="dialog-close" mat-dialog-close>
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</ng-container>
<section class="dialog-content" mat-dialog-content>
  <div [formGroup]="form" class="padding-top-3">
    <div>
      <erp-label class="margin-bottom-1">
        <ng-container i18n="@@sales.sales-order.popup.reason"> Reason: </ng-container>
      </erp-label>
      <erp-textarea rows="10" formControlName="reason" [isFocused]="true"> </erp-textarea>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button class="dialog-action-confirm" color="accent" (click)="onConfirm()">
    <ng-container i18n="@@common.buttons.yes"> Yes </ng-container>
  </erp-button>
</section>
