import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { map, Observable } from 'rxjs';
import { IERPWorkflowProgress, IERPWorkflowStatuses, IInvoiceAvaluableTransitions } from '../interfaces';
import { Cache } from '../decorators';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedWorkflowService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('workflowBrowse');
  }

  @Cache()
  invoiceWorkflowStatuses(): Observable<IERPWorkflowStatuses[]> {
    return this.get<IERPWorkflowStatuses[]>({}, 'types/8/statuses').pipe(
      map(statuses => {
        return statuses.map(status => ({
          ...status,
          dataInfluence: status.dataInfluence.map(dataInfluenceItem => ({
            ...dataInfluenceItem,
            properties: (dataInfluenceItem.properties as string).split(';')
          }))
        }));
      })
    );
  }

  getAvailableStatusesByDocumentId(currentStatusId: number, invId: number): Observable<IInvoiceAvaluableTransitions[]> {
    const params: { [key: string]: string | number | boolean } = {};
    params.currentStatusId = currentStatusId;
    params.includeDisabled = false;

    return this.get<IInvoiceAvaluableTransitions[]>(params, `${invId}/availableTransitions`);
  }

  getAvailableStatusesByStatusId(currentStatusId: number): Observable<IInvoiceAvaluableTransitions[]> {
    const params: { [key: string]: string | number | boolean } = {};
    params.currentStatusId = currentStatusId;

    return this.get<IInvoiceAvaluableTransitions[]>(params, 'availableStatuses');
  }

  getWorkflowProgressByDocumentId(invId: number): Observable<IERPWorkflowProgress> {
    return this.get<IERPWorkflowProgress>({}, `${invId}/progress`);
  }
}
